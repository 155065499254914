import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpGet } from '../../helpers/http.js';

export const searchCatalogItems = createAsyncThunk(
    'catalog/search',
    async (args, { getState }) => {
        const { search, portals } = args;
        const { env, auth } = getState();

        const params = new URLSearchParams({ search: search });
        if (portals) {
            portals.forEach((id) => params.append('portals', id));
        }

        const response = await httpGet(`${env.settings.catalogItemsEndpoint}?${params}`, auth.user);

        return {
            list: response.data,
            total: response.data?.length,
            search: search,
        };
    },
    {
        condition: (_, { getState }) => {
            const { catalog: state } = getState();
            if (state.status === 'loading') {
                return false;
            }
        },
    },
);

const catalogSlice = createSlice({
    name: 'catalog',
    initialState: {
        status: 'idle',
        list: [],
        total: 0,
        error: null,
    },
    reducers: {
        clearSearchResults: (state) => {
            state.list = [],
            state.total = 0;
            state.search = '';
    },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchCatalogItems.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(searchCatalogItems.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list = payload.list,
                state.total = payload.total;
                state.search = payload.search;
            })
            .addCase(searchCatalogItems.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

export const {
    clearSearchResults,
} = catalogSlice.actions;

export default catalogSlice.reducer;
