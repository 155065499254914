export const getItemLaunchType = (elearning) => {
    switch (elearning.playerType) {
        case 0:
        case 2:
            return 'popup-close';
        case 1:
        default:
            return 'popup';
    }
}
