import { html } from 'lit';
import { translate } from '../../helpers/translate.js';
import '@vaadin/item/theme/material/vaadin-item.js';
import '@vaadin/list-box/theme/material/vaadin-list-box.js';
import '@sdblearning/sdb-learning-frontend';

export const compoundTypes = {
    SINGLE_ITEM: 'single-item',
    COMPOUND: 'compound',
    COMPOUND_TEST: 'compound-test',
};

export const itemTypes = {
    MOVIE: 'movie',
    AUDIO: 'audio',
    ANIMATION: 'animation',
    INFOGRAPHIC: 'infographic',
    EXERCISE: 'exercise',
    MICROCOURSE: 'microcourse',
    TEXT: 'text',
    TEST: 'test',
    PRETEST: 'pretest',
    ELEARNING: 'elearning',
};

// remove when can be transported as string
export const compositionTypes = {
    COMPOSITIONTYPE_REGULAR: 0,
    COMPOSITIONTYPE_SINGLEITEM: 1,
    COMPOSITIONTYPE_LEGACY: 2
};

export const completionTypes = {
    REQUIRED_BLOCKS: 0,
    ANY_BLOCK: 1,
    ALL_BLOCKS: 2,
    MIN_NUM_BLOCKS: 3,
};

export const compoundCompletionTypes = {
    ANY_ITEM: 0,
    ALL_ITEMS: 1,
};

export const isRegular = (type) => type === compositionTypes.COMPOSITIONTYPE_REGULAR || type === compositionTypes.COMPOSITIONTYPE_LEGACY;

export const isCompoundItem = (type) => [compoundTypes.COMPOUND, compoundTypes.COMPOUND_TEST].indexOf(type) > -1;

export const isCompoundTestItem = (type) => type === compoundTypes.COMPOUND_TEST;

export const toCompoundType = (type) => isCompoundItem(type) ? type : compoundTypes.SINGLE_ITEM;

export const getIcon = (type) => {
    switch (type) {
        case itemTypes.MOVIE: return 'fal fa-video';
        case itemTypes.AUDIO: return 'fal fa-volume';
        case itemTypes.ANIMATION: return 'fal fa-images';
        case itemTypes.INFOGRAPHIC: return 'fal fa-image';
        case itemTypes.EXERCISE: return 'fal fa-check-to-slot';
        case itemTypes.MICROCOURSE: return 'fal fa-box-open';
        case itemTypes.TEXT: return 'fal fa-file-lines';
        case itemTypes.TEST: return 'fal fa-graduation-cap';
        case itemTypes.PRETEST: return 'fal fa-square-check';
        case itemTypes.ELEARNING: return 'fal fa-boxes-stacked';
        default: return 'fal fa-boxes-stacked';
    }
};

export const itemTypeRenderer = () => html`
    <vaadin-list-box>
        ${Object.values(itemTypes).map((t) => html`
            <vaadin-select-item value=${t}>
                <span style="display:flex">
                    <sdb-content-icon style="margin-right: 10px" icon=${getIcon(t)} class="medium fallback-icon"></sdb-content-icon>
                    <span class="type-label" value=${t}>${translate(t)}</span>
                </span>
            </vaadin-select-item>
        `)}
    </vaadin-list-box>
`;


