import { createAsyncThunk, createSlice, isRejected } from '@reduxjs/toolkit';
import { itemTypes, compoundTypes, isCompoundItem } from './types.js';
import { httpGet, httpPost, httpPut, httpDelete, PAGESIZE } from '../../helpers/http.js';
import { compositionTypes } from './types.js';
import { assignAndIsDirty } from '../../helpers/extend.js';
import { translate } from '../../helpers/translate.js';
import { uuidv4 } from '../../helpers/uuid.js';

export const loadDraftsIfNeeded = createAsyncThunk(
    'draft/fetch',
    async (args, { getState }) => {
        const { draft: state, env, auth } = getState();

        const portalId = env?.portalIdentifier || '';
        const newPage = args.reload ? 0 : state.page + 1;
        const params = new URLSearchParams({
            page: newPage,
            pageSize: PAGESIZE,
        });

        const search = args.search || args.search === '' ? args.search : state.search;
        if (search) {
            params.set('search', search);
        }

        const response = await httpGet(`${env.settings.draftsEndpoint}/${portalId}?${params}`, auth.user);

        return {
            list: response.data,
            page: newPage,
            total: response.total,
            reload: args.reload,
            search: search,
        };
    },
    {
        condition: (args, { getState }) => {
            const { draft: state } = getState();
            if (!args.reload && (args.index < state.list.length - 1 || args.index >= state.total - 1)) {
                return false;
            }
        },
    },
);

export const loadDraft = createAsyncThunk(
    'draft/fetchOne',
    async (args, { getState }) => {
        const { draftId } = args;
        const { env, auth } = getState();

        return await httpGet(`${env.settings.draftEndpoint}${draftId}`, auth.user);
    },
    {
        condition: (args, { getState }) => {
            const { draft: state } = getState();
            if (args.draftId === state.current?.id) {
                return false;
            }
        },
    },
);

export const publishDraft = createAsyncThunk(
    'draft/publish',
    async (args, { getState }) => {
        const { draftId } = args;
        const { env, auth } = getState();

        return await httpPost(`${env.settings.publishEndpoint}${draftId}`, auth.user, {
            publishMode : 0, // replace
        });
    },
);

export const createDraft = createAsyncThunk(
    'draft/create',
    async (args, { getState }) => {
        const { env, auth } = getState();
        const portalId = env?.portalIdentifier || '';

        return await httpPost(`${env.settings.draftEndpoint}`, auth.user, {
            ...args,
            portalId: portalId,
            compositionType: env?.hasGlobalId
                ? compositionTypes.COMPOSITIONTYPE_LEGACY
                : compositionTypes.COMPOSITIONTYPE_REGULAR,
        });
    },
);

export const duplicateDraft = createAsyncThunk(
    'draft/duplicate',
    async (args, { getState }) => {
        const { draftId } = args;
        const { env, auth } = getState();

        return await httpPost(`${env.settings.draftEndpoint}copy/${draftId}`, auth.user, {});
    },
);

export const deleteDraft = createAsyncThunk(
    'draft/delete',
    async (args, { getState }) => {
        const { draftId } = args;
        const { env, auth } = getState();

        await httpDelete(`${env.settings.draftEndpoint}${draftId}`, auth.user);
        return {
            id: draftId,
        };
    },
);

export const saveDraft = createAsyncThunk(
    'draft/save',
    async (args, { getState }) => {
        const { draftId } = args;
        const { draft: state, env, auth } = getState();

        return await httpPut(`${env.settings.draftEndpoint}${draftId}`, auth.user, state.current);
    },
);

const draftSlice = createSlice({
    name: 'draft',
    initialState: {
        page: -1,
        list: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        updateDraft: (state, action) => {
            const { payload } = action;

            if (assignAndIsDirty(state.current, payload.changes)) {
                state.dirty = true;
            }
        },
        createDraftItem: (state) => {
            state.current.items.push({
                uniqueId: uuidv4(),
                disabled: false,
                name: translate('newBlock'),
                type: '',
                description: null,
                duration: null,
                masteryScore: null,
                launchType: 'popup',
                compositionSuccessDeterminator: null,
                items: [],
            });
        },
        updateDraftItem: (state, action) => {
            const { payload } = action;

            let item = state.current.items.find((i) => isItem(i, payload.itemId));

            if (assignAndIsDirty(item, payload.changes)) {
                state.dirty = true;
            }
        },
        copyDraftItem: (state, action) => {
            const { payload } = action;

            state.current.items.push({
                ...payload.item,
                uniqueId: uuidv4(),
                name: `${payload.item.name} - ${translate('copy')}`,
                items: (payload.item.items || []).map((subItem) => ({
                    ...subItem,
                    uniqueId: uuidv4(),
                })),
            });
            state.dirty = true;
        },
        changeCompoundType: (state, action) => {
            const { payload } = action;
            let item = state.current.items.find((item) => isItem(item, payload.itemId));
            let changes = {
                type: payload.newType,
            };

            // when we move from a single to a compound type and there's a lti item linked
            if (isCompoundItem(payload.newType) && !isCompoundItem(item.type) && item.launchId) {
                changes = {
                    ...changes,
                    items: [{
                        uniqueId: uuidv4(),
                        disabled: false,
                        name: translate(item.type || 'newBlockItem'),
                        type: item.type,
                        duration: item.duration,
                        launchId: item.launchId,
                        launchType: item.launchType,
                    }],
                    launchId: null,
                };
            }

            // when we go back from a compound to a single type, just take the first subitem as the new item
            if (!isCompoundItem(payload.newType) && isCompoundItem(item.type)) {
                const child = item.items.length > 0 ? item.items[0] : {};
                changes = {
                    ...changes,
                    type: child.type || itemTypes.ELEARNING, // overwrite type cause we now have a single item type
                    launchId: child.launchId,
                    launchType: child.launchType,
                    duration: child.duration,
                    items: [],
                    masteryScore: null,
                    progressType: null,
                };
            }

            if (payload.newType === compoundTypes.COMPOUND) {
                changes = {
                    ...changes,
                    masteryScore: null,
                    progressType: 0, // anyItem
                };
            }

            if (payload.newType === compoundTypes.COMPOUND_TEST) {
                changes = {
                    ...changes,
                    masteryScore: null,
                    progressType: null,
                };
            }

            if (assignAndIsDirty(item, changes)) {
                state.dirty = true;
            }
        },
        deleteDraftItem: (state, action) => {
            const { payload } = action;

            state.current.items = state.current.items.filter((item) => !isItem(item, payload.itemId));
            state.dirty = true;
        },
        sortDraftItems: (state, action) => {
            const { payload } = action;

            state.current.items = payload.indexes.map((i) => [...state.current.items][i.originalIndex]);
            state.dirty = true;
        },
        moveDraftItem: (state, action) => {
            const { payload } = action;
            const index = state.current.items.findIndex((item) => isItem(item, payload.itemId));

            state.current.items = moveItem([...state.current.items], index, payload.direction);
            state.dirty = true;
        },
        createDraftSubItem: (state, action) => {
            const { payload } = action;
            const item = state.current.items.find((i) => isItem(i, payload.itemId));

            item.items.push({
                uniqueId: uuidv4(),
                disabled: false,
                name: translate('newBlockItem'),
                type: '',
                description: null,
                duration: null,
            });
            state.dirty = true;
        },
        updateDraftSubItem: (state, action) => {
            const { payload } = action;
            const item = state.current.items.find((i) => isItem(i, payload.itemId));
            let subItem = item.items.find((i) => isItem(i, payload.subItemId));

            if (assignAndIsDirty(subItem, payload.changes)) {
                state.dirty = true;
            }
        },
        copyDraftSubItem: (state, action) => {
            const { payload } = action;
            const item = state.current.items.find((i) => isItem(i, payload.itemId));

            item.items.push({
                ...payload.subItem,
                uniqueId: uuidv4(),
                name: `${payload.subItem.name} - ${translate('copy')}`,
            });
            state.dirty = true;
        },
        deleteDraftSubItem: (state, action) => {
            const { payload } = action;
            const item = state.current.items.find((i) => isItem(i, payload.itemId));

            item.items = item.items.filter((i) => !isItem(i, payload.subItemId));
            state.dirty = true;
        },
        sortDraftSubItems: (state, action) => {
            const { payload } = action;
            const item = state.current.items.find((i) => isItem(i, payload.itemId));

            item.items = payload.indexes.map((i) => [...item.items][i.originalIndex]);
            state.dirty = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadDraftsIfNeeded.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadDraftsIfNeeded.fulfilled, (state, action) => {
                const { reload, list, page, total, search } = action.payload;

                state.status = 'success';
                state.list = reload ? [...list] : [...state.list, ...list];
                state.page = page;
                state.total = total;
                state.search = search;
            })
            .addCase(loadDraft.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadDraft.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.dirty = false;
                state.current = payload;
                if (!state.current.progressSettings)
                    state.current.progressSettings = {};
            })
            .addCase(publishDraft.pending, (state) => {
                state.status = 'publishing';
            })
            .addCase(publishDraft.fulfilled, (state, action) => {
                const { payload } = action;
                let draft = state.list.find((d) => d.id === payload.id);

                state.status = 'success';
                draft = Object.assign(draft, payload);
            })
            .addCase(createDraft.pending, (state) => {
                state.status = 'creating';
            })
            .addCase(createDraft.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list.unshift(payload);
                state.total++;
            })
            .addCase(duplicateDraft.pending, (state) => {
                state.status = 'duplicating';
            })
            .addCase(duplicateDraft.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list.unshift(payload);
            })
            .addCase(deleteDraft.pending, (state) => {
                state.status = 'deleting';
            })
            .addCase(deleteDraft.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list = state.list.filter((d) => d.id !== payload.id);
                state.total--;
            })
            .addCase(saveDraft.pending, (state) => {
                state.status = 'saving';
            })
            .addCase(saveDraft.fulfilled, (state) => {
                state.status = 'success';
                state.dirty = false;
            })
            .addMatcher(
                isRejected(
                    loadDraftsIfNeeded,
                    loadDraft,
                    publishDraft,
                    createDraft,
                    duplicateDraft,
                    deleteDraft,
                    saveDraft
                ),
                (state, action) => {
                    state.status = 'failed';
                    state.error = action.error;
                },
            );
    },
});

const isItem = (item, itemId) => item.uniqueId === itemId;

const moveItem = (items, index, direction) => direction === 'up'
    ? move([...items], index, index - 1, 1)
    : move([...items], index, index + 1, 1);

const move = (array, from, to, on = 1) => {
    return array = array.slice(), array.splice(to, 0, ...array.splice(from, on)), array;
};

export const {
    updateDraft,
    createDraftItem,
    updateDraftItem,
    copyDraftItem,
    changeCompoundType,
    deleteDraftItem,
    sortDraftItems,
    moveDraftItem,
    createDraftSubItem,
    updateDraftSubItem,
    copyDraftSubItem,
    deleteDraftSubItem,
    sortDraftSubItems,
} = draftSlice.actions;

export default draftSlice.reducer;
