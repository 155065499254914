/* eslint-disable indent */
import { html, css, nothing, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import sharedStyles from '../../app/styles.js';
import {
    updateDraftSubItem,
    copyDraftSubItem,
    deleteDraftSubItem
} from '../../store/draft/draft.js';
import {
    previewElearning,
    previewMicrolearning,
 } from '../../store/preview/preview.js';
import { itemTypes, getIcon, itemTypeRenderer } from '../../store/draft/types.js';
import { translate } from '../../helpers/translate.js';
import { toDuration, fromDuration } from '../../helpers/converters.js';
import { scrollIntoViewIfNeeded } from '../../helpers/dom.js';
import { selectRenderer } from '@vaadin/select/lit.js';
import { getItemLaunchType } from '../../store/draft/helpers.js'
import '@sdblearning/sdb-learning-frontend';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-input/paper-input.js';
import '@vaadin/select/theme/material/vaadin-select.js';
import '../elearning/sdb-microlearning-select-input.js';
import '../elearning/sdb-elearning-select-input.js';

const mapStateToProps = (state) => ({
    hasGlobalId: state.env.hasGlobalId,
    legacy: state.draft.current.compositionType === 2, // LegacyMicrolearnings
});

const mapDispatchToProps = (dispatch) => ({
    updateDraftSubItem: (itemId, subItemId, changes) => dispatch(updateDraftSubItem({ itemId, subItemId, changes })),
    copyDraftSubItem: (itemId, subItem) => dispatch(copyDraftSubItem({ itemId, subItem })),
    deleteDraftSubItem: (itemId, subItemId) => dispatch(deleteDraftSubItem({ itemId, subItemId })),
    previewElearning: (item) => dispatch(previewElearning({ item })),
    previewMicrolearning: (microlearning) => dispatch(previewMicrolearning({ microlearning })),
});

@customElement('sdb-composition-draft-sub-item')
class SdbCompositionDraftSubItem extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor parentId;

    @property({ type: Object })
    accessor item;

    @state()
    accessor inEditMode = false;

    get deleteSubItemDialog() {
        return this.renderRoot.querySelector('#delete_sub_item_dialog');
    }

    get subItemBottom() {
        return this.renderRoot.querySelector('#sub_item_bottom');
    }

    static get styles() {
        return [
            sharedStyles,
            css`
                /* css is a mess, fix in new layout */
                paper-icon-item {
                    margin-left: 0px !important;
                    background-color: white;
                }

                paper-item:not([disabled]) {
                    cursor: pointer;
                }

                paper-item-body {
                    border: none;
                    width: 100%;
                }

                paper-item-body div.title {
                    font-family: var(--font-family);
                    font-size: 16px;
                    font-weight: 500;
                    color: var(--text-primary);
                    --webkit-font-smoothing: antialiased;
                    margin-left: 26px;
                }

                iron-icon.fallback-icon, paper-icon-button {
                    color: rgba(0, 0, 0, .7);
                }

                paper-icon-item {
                    margin-left: 0px !important;
                    background-color: white;
                    width: 100%;
                }

                paper-icon-button, iron-icon {
                    color: rgba(0, 0, 0, .7);
                }

                paper-icon-button[disabled] {
                    color: var(--paper-icon-button-disabled-text, var(--disabled-text-color));
                }

                iron-icon.medium {
                    --iron-icon-height: 24px;
                    --iron-icon-width: 24px;
                }

                .flex-row {
                    display: flex;
                    flex-direction: row;
                }

                .flex-column {
                    display: flex;
                    flex-direction: column;
                }

                .edit-mode {
                    margin-left: 50px;
                    margin-right: 20px;
                }

                paper-input.small {
                    width: 300px;
                }
            `,
        ];
    }

    render() {
        const update = (changes) => this.updateDraftSubItem(this.parentId, this.item.uniqueId, changes);

        return html`
            <div class="flex-row">
                <paper-icon-item>
                    <div slot="item-icon"></div>
                    <sdb-content-icon
                        icon="${getIcon(this.item.type)}"
                        style="margin-left: -10px;"
                        class="medium">
                    </sdb-content-icon>
                    <paper-item-body
                        style="cursor: pointer;"
                        @click=${() => { this._toggleEditMode(); }}>
                        <div class="title">${this.item.name}</div>
                    </paper-item-body>
                    <paper-menu-button dynamic-align>
                        <sdb-content-iconbutton
                            icon="fal fa-ellipsis-vertical"
                            slot="dropdown-trigger">
                        </sdb-content-iconbutton>
                        <paper-listbox slot="dropdown-content">
                            <paper-item
                                @click=${() => { this.copyDraftSubItem(this.parentId, this.item); }}>
                                ${translate('duplicate')}
                            </paper-item>
                            <paper-item
                                ?disabled=${!this.item.launchId}
                                @click=${() => {
                                    !this.item.playerType
                                        ? this.previewMicrolearning(this.item)
                                        : this.previewElearning(this.item);
                                }}>
                                ${translate('preview')}
                            </paper-item>
                            <paper-item
                                class="warning"
                                @click=${() => { this.deleteSubItemDialog.open(); }}>
                                ${translate('delete')}
                            </paper-item>
                        </paper-listbox>
                    </paper-menu-button>
                </paper-icon-item>
            </div>

            ${this.inEditMode ? html`
                <div>
                    <div class="flex-column edit-mode">
                        ${this.hasGlobalId && this.legacy
                            ? html`
                                <sdb-microlearning-select-input
                                    id="elearning_select"
                                    labelText=${translate('blockItem')}
                                    launchId=${this.item.launchId}
                                    @itemSelected=${(e) => { update({
                                        launchType: 'popup',
                                        launchId: e.detail.item.targetId,
                                    }); }}>
                                </sdb-microlearning-select-input>
                            `
                            : html`
                                <sdb-elearning-select-input
                                    id="elearning_select"
                                    labelText=${translate('blockItem')}
                                    launchId=${this.item.launchId}
                                    launchName=${this.item.elearningItemName}
                                    @itemSelected=${(e) => { update({
                                        launchId: e.detail.elearning.targetId,
                                        elearningItemName: e.detail.elearning.title,
                                        launchType: getItemLaunchType(e.detail.elearning),
                                        playerType: e.detail.elearning.playerType,
                                    });}}>
                                </sdb-elearning-select-input>
                            `
                        }

                        <paper-input
                            id="title_input"
                            transparent
                            label=${translate('displayTitle')}
                            value=${this.item.name}
                            @change="${(e) => { update({ name: e.target.value }); }}">
                        </paper-input>

                        <vaadin-select
                            id="itemtype_select"
                            transparent
                            label=${translate('blockItemType')}
                            value=${this.item.type}
                            @value-changed=${(e) => { update({ type: e.target.value }); }}
                            ${selectRenderer(itemTypeRenderer, itemTypes)}>
                        </vaadin-select>


                        <paper-input
                            id="duration_input"
                            transparent
                            class="small"
                            type="number"
                            label=${translate('duration')}
                            value=${fromDuration(this.item.duration)}
                            @change=${(e) => { update({ duration: toDuration(e.target.value) }); }}>
                            <div slot="suffix">${translate('minutes')}</div>
                        </paper-input>

                    </div>

                    <div style="height: 15px;"></div>
                </div>` : nothing
            }

            <div id="sub_item_bottom"></div>

            <sdb-dialog
                type="confirm"
                id="delete_sub_item_dialog"
                heading=${translate('deleteBlockItemHeading')}
                confirmCaption=${translate('delete')}
                @confirm=${() => { this.deleteDraftSubItem(this.parentId, this.item.uniqueId); }}>
            </sdb-dialog>
        `;
    }

    _toggleEditMode() {
        this.inEditMode = !this.inEditMode;
        if (this.inEditMode) {
            setTimeout(() => {
                scrollIntoViewIfNeeded(this.subItemBottom);
            }, 0);
        }
    }
}

