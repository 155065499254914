export const fromDuration = (duration) => !isEmpty(duration)
    ? Math.ceil((parseInt(duration, 10) || 0) / 60)
    : null;

export const toDuration = (minutes) => !isEmpty(minutes)
    ? Math.ceil((parseInt(minutes, 10) || 0) * 60)
    : null;

export const fromLtiScore = (ltiScore) => !isEmpty(ltiScore)
    ? (parseFloat(ltiScore, 10) || 0) * 100
    : null;

export const toLtiScore = (score) => !isEmpty(score)
    ? (parseInt(score, 10) || 0) / 100
    : null;

    const isEmpty = (value) => value === undefined || value === null || value === '';
