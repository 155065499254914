/* eslint-disable max-len */
export default {
    'en': {
        'menuDrafts': 'Compositions',
        'menuPublished': 'Published',
        'headerDrafts': 'Compositions view',
        'headerCompositions': 'Published compositions view',
        'headerPublications': 'Publications',
        'headerPortals': 'Portals',
        'headerLtiItems': 'LTI items',
        'headerScormItems': 'SCORM packages',
        'headerOsItems': 'OS Microlearnings',
        'headerDistributions': 'Distributions',
        'headerReplace': 'Replace',

        'single-item': 'Single microlearning',
        'compound': 'Block with multiple microlearnings',
        'compound-test': 'Assessment block',
        'movie': 'Video',
        'animation': 'Animation',
        'audio': 'Audio',
        'infographic': 'Infographic',
        'exercise': 'Exercise',
        'microcourse': 'Microcourse',
        'text': 'Text',
        'test': 'Test',
        'pretest': 'Assessment',
        'elearning': 'E-learning',
        'accreditationinfo': 'accreditation settings',
        'accreditations': 'accreditations',

        'draftPublished': 'composition published',
        'draftCreated': 'composition created',
        'draftDuplicated': 'composition duplicated',
        'draftDeleted': 'composition deleted',
        'draftSaved': 'composition saved',
        'compositionDistributed': 'composition distributed',
        'compositionRollbacked': 'rollback completed',
        'catalogItemsReplaced': 'catalog item(s) replaced',
        'ltiItemPublished': 'LTI item published',
        'ltiItemUnpublished': 'Rolled back LTI item publication',
        'ltiItemCreated': 'LTI item created',
        'ltiItemDuplicated': 'LTI item duplicated',
        'ltiItemDeleted': 'LTI item deleted',
        'ltiItemSaved': 'LTI item saved',
        'scormItemPublished': 'SCORM item published',
        'ltiItemUnpublished': 'Rolled back SCORM item publication',
        'scormItemCreated': 'SCORM item created',
        'scormItemDeleted': 'SCORM item deleted',
        'scormItemSaved': 'SCORM item saved',
        'accreditationsSaved': 'Accreditation settings saved',
        'accreditationsProcessed': 'Accreditations have been processed',

        'copy': 'Copy',
        'newBlock': 'New block',
        'newBlockItem': 'New block item',

        'publish': 'publish',
        'unpublish': 'rollback publication',
        'preview': 'preview',
        'duplicate': 'duplicate',
        'delete': 'delete',
        'remove': 'remove',
        'create': 'create',
        'save': 'save',
        'ok': 'ok',
        'cancel': 'cancel',
        'preview': 'preview',
        'select': 'select',
        'distribute': 'distribute',
        'add': 'add',
        'preset': 'use preset',
        'continue': 'continue',
        'replace': 'replace',
        'rollback': 'rollback',
        'sort': 'sort',

        'publishHeading': 'Publish composition?',
        'publishHeadingLtiItem': 'Publish LTI item?',
        'publishHeadingScormItem': 'Publish SCORM package?',
        'publishBody': 'The current version of this composition will be published.',
        'publishBodyLtiItem': 'This LTI item will be published to the Catalog.',
        'publishBodyScormItem': 'This SCORM package will be published to the Catalog.',
        'unpublishHeadingLtiItem': 'Rollback publication LTI item?',
        'unpublishBodyLtiItem': 'This LTI item will be removed from the Catalog.',
        'unpublishBodyScormItem': 'This SCORM package will be removed from the Catalog.',
        'duplicateHeading': 'Duplicate composition?',
        'duplicateHeadingtiItem': 'Duplicate LTI?',
        'deleteHeading': 'Delete composition?',
        'deleteHeadingtiItem': 'Delete LTI?',
        'deleteHeadingScormItem': 'Delete SCORM package?',
        'createHeading': 'Create new composition?',
        'createHeadingtiItem': 'Create new LTI item?',
        'createHeadingScormItem': 'Create new SCORM package?',
        'urlHeading': 'Enter a URL for the image',
        'deleteBlockHeading': 'Delete block?',
        'deleteBlockItemHeading': 'Delete block item?',
        'chooseMicrolearning': 'Select a microlearning',
        'chooseElearning': 'Select an e-learning item',
        'distributeHeading': 'Distribute composition?',
        'distributeBody': '"${this.compositionName}" will be distributed to the following portals:',
        'replaceHeading': 'Replace catalog items?',
        'replaceBody': '"${this.compositionName}" will replace the selected catalog item on the following portals:',
        'presetHeading': 'Use preset settings',
        'presetBody': 'Would you like set all settings to this supplier\'s preset? This will overwrite all current settings.',
        'dirtyFormHeading': 'Unsaved changes',
        'dirtyFormBody': 'There are changes made that are not saved. Are you sure you want to continue?',
        'processHeading': 'Process accreditations',
        'processBody': 'All the results of this course will be processed at once. This could take some time. Do you want to continue processing?',
        'rollbackHeading': 'Rollback replace',
        'rollbackBody': 'Are you sure you want to rollback the replace of this catalogitem. All properties will be restored if possible, users will be assigned to the previous catalog item.',

        'lastModified': 'last modified',
        'lastPublished': 'last published',
        'generalSettings': 'General settings',
        'title': 'Draft name',
        'description': 'Description',
        'displayTitle': 'Title',
        'summary': 'Summary',
        'duration': 'Duration',
        'requiredDuration': 'Required duration',
        'lti': 'LTI link',
        'url': 'URL',
        'filename': 'Filename',
        'masteryScore': 'Mastery score',
        'blockType': 'Block type',
        'blockItem': 'Block item',
        'blockItemType': 'Block item type',
        'successDeterminator': 'Required for completion',
        'minutes': 'minutes',
        'search': 'Search',
        'numberResults': 'Number of results',
        'noMicrolearningsFound': 'No microlearnings were found',
        'noElearningsFound': 'No e-learning items were found',
        'noCompositionsFound': 'No compositions were found',
        'noLtiItemsFound': 'No LTI items were found',
        'noScormItemsFound': 'No SCORM packages were found',
        'noOsItemsFound':  'No Online Studio microlearnings were found',
        'noAccreditationsFound': 'No accreditations were found',
        'noAccreditationInfoFound': 'No accreditation settings were found',
        'noReplacements': 'No catalog items have been replaced',
        'invalidScormPackage': 'Not a valid SCORM 1.2 package.',
        'search': 'search',
        'distributePortalsLabel': 'Portals',
        'distributePortalsPlaceholder': 'Select 1 or more portals',
        'noPortals': 'Not distributed yet.',
        'name': 'Name',
        'launchUrl': 'Launch Url',
        'consumerKey': 'Consumer Key',
        'sharedSecret': 'Shared Secret',
        'settings': 'Settings',
        'finishImmediately': 'Will complete immediately',
        'finishAfterNumberOfGrades': 'Completes after number of grades',
        'finishScore': 'Score',
        'masteryScore': 'Masteryscore',
        'canScore': 'Sends grade',
        'bigNumberParameter': 'BIG number parameter',
        'finishScore': 'Finish Score',
        'masteryScore': 'Mastery Score',
        'canScore': 'Report grade',
        'fullScreen': 'Full screen',
        'requireNewWindow': 'Require new window',
        'anonymous': 'Anonymous',
        'parameters': 'Parameters',
        'newKey': 'New parameter',
        'keys': 'Keys',
        'completionType': 'Completion type',
        'scoreType': 'Report',
        'keySecretsList': 'Existing key secrets',
        'supplier': 'Supplier',
        'playerType': 'Type',
        'all': 'All',
        'replaceElearning': 'Catalog item to be replaced',
        'replacedElearning': 'Replaced catalog item',
        'visibleUntil': 'Visible until',
        'latestVersion': 'Latest version',
        'vEnVnNumberParameter': 'V&VN number parameter',
        'version': 'Version',
        'package': 'Package',
        'uploaded': 'Uploaded',
        'uploadNewVersion': 'Upload new version',
        'uploadNewPackage': 'Upload new package',
        'scormPackageNotEnabled': 'SCORM package is not ready to display',
        'loading': 'loading data...',
        'selectElearningItem': 'Select an e-learning item',
        'launchType': 'Launch type',
        'newWindowMessage': 'This course will be launched in a new window. Please click the button to open the window.',
        'newWindowOpenedMessage': 'The course has been automatically opened in a new window.',
        'startInNewWIndow': 'Click here to start the course',
        'completionSettings': 'Completion settings',
        'completionType': 'Blocks completion',
        'progressType': 'Items completion',
        'minItems': 'Number of blocks',

        'REQUIRED_BLOCKS': 'Select which blocks need to be completed',
        'ANY_BLOCK': 'At least one block needs to be completed',
        'ALL_BLOCKS': 'All blocks need to be completed',
        'MIN_NUM_BLOCKS': 'At least this number of blocks need to be completed',
        'ANY_ITEM': 'At least one item needs to be completed',
        'ALL_ITEMS': 'All items must be completed',

        'helper_min2chars': 'Please enter at least 2 characters to begin your search',
        'helper_portalSearch': 'Please select 1 or more portals',
        'helper_activeWhenPortalsSelected': 'Please select at least 1 portal.',
        'helper_copyOptions': 'Assignments and Skills will be replaced automatically',
        'warning_replaceToPortals': 'This catalog item will be replaced on the following portals:',
        'warning_noReplace': 'No portals found where replacement can be made.',
        'replaceAgain': 'Also replace again on this portals',
        'allPortals': 'all portals',

        'expired': 'expired',
        'aboutToExpire': 'about to expire',
        'status': 'Status',
        'process': 'Process',
        'processed': 'Processed',
        'result': 'result',
        'user': 'user',

        'accr.status.accepted': 'Accepted',
        'accr.status.failed': 'Failed',
        'accr.status.error': 'Error',
        'accr.status.invalid': 'Invalid',
        'accr.status.badResponse': 'Bad response',
        'accr.endDate': 'Completed',

        'preset_default': 'Completes when a grade of 100 is send',
        'preset_immediately': 'Completes immediately',
        'preset_firstResult': 'Completes on the first grade',
        'preset_score': 'Completes when the grade is higher or equal to',
        'preset_noScore': 'No grade',
        'preset_showScore': 'Grade',
        'preset_masteryScore': 'Grade and passed/failed based on the mastery score of',

        'pe.externalPersonId': 'PE-online Person ID',
        'pe.courseId': 'PE-online Course ID',
        'pe.editionId': 'PE-online Edition ID',
        'pe.moduleId': 'PE-online Module ID',
        'pe.organization': 'Professional body',
        'pe.duration': 'Duration',
        'pe.duration.start': 'Duration start date',
        'pe.duration.end': 'Duration end date',
        'pe.institution': 'Institution',
        'pe.points': 'Points',

        'copyOptions': 'Copy the following catalog properties',
        'flags.title': 'Title',
        'flags.description': 'Description',
        'flags.summary': 'Summary',
        'flags.image': 'Image',
        'flags.categories': 'Categories',
        'flags.documents': 'Documents',
        'flags.evaluation': 'Evaluation form',
        'flags.certificate': 'Certficate',

        'errChooseBetween0_10': 'Choose a number between 0 and 10',
        'errChooseBetween0_100': 'Choose a score between 0 and 100',
        'errRequired': 'This field is required',
        'errorOccurred': 'An error occurred',

        'launchtype_regular': 'In the current window (default)',
        'launchtype_newwindow': 'In a new window',
    },
    'nl': {
        'menuDrafts': 'Arrangementen',
        'menuPublished': 'Gepubliceerd',
        'headerDrafts': 'Overzicht arrangementen',
        'headerCompositions': 'Overzicht gepubliceerde arrangementen',
        'headerPublications': 'Publicaties',
        'headerPortals': 'Portalen',
        'headerLtiItems': 'LTI koppelingen',
        'headerScormItems': 'SCORM pakketten',
        'headerOsItems': 'OS Microlearnings',
        'headerDistributions': 'Uitleveringen',
        'headerReplace': 'Vervangen',

        'single-item': 'Los e-learning onderdeel',
        'compound': 'Blok met 1 of meerdere e-learning onderdelen',
        'compound-test': 'Blok met 1 of meerdere toetsen',
        'movie': 'Video',
        'audio': 'Audio',
        'animation': 'Animatie',
        'infographic': 'Infographic',
        'exercise': 'Oefenen',
        'microcourse': 'Microcursus',
        'text': 'Tekst',
        'test': 'Toets',
        'pretest': 'Test jezelf',
        'elearning': 'E-learning',
        'accreditationinfo': 'accreditatie instellingen',
        'accreditations': 'accreditaties',

        'draftPublished': 'arrangement gepubliceerd',
        'draftCreated': 'arrangement aangemaakt',
        'draftDuplicated': 'arrangement gedupliceerd',
        'draftDeleted': 'arrangement verwijderd',
        'draftSaved': 'arrangement opgeslagen',
        'compositionDistributed': 'arrangement uitgeleverd',
        'compositionRollbacked': 'vervangen hersteld',
        'catalogItemsReplaced': 'catalogus item(s) vervangen',
        'ltiItemPublished': 'LTI koppeling gepubliceerd',
        'ltiItemUnpublished': 'Publicatie LTI koppeling teruggedraaid',
        'ltiItemCreated': 'LTI koppeling aangemaakt',
        'ltiItemDuplicated': 'LTI koppeling gedupliceerd',
        'ltiItemDeleted': 'LTI koppeling verwijderd',
        'ltiItemSaved': 'LTI koppeling opgeslagen',
        'scormItemPublished': 'SCORM pakket gepubliceerd',
        'scormItemUnpublished': 'Publicatie SCORM pakket teruggedraaid',
        'scormItemCreated': 'SCORM pakket aangemaakt',
        'scormItemDeleted': 'SCORM pakket verwijderd',
        'scormItemSaved': 'SCORM pakket opgeslagen',
        'accreditationsSaved': 'Accreditatie instellingen opgeslagen',
        'accreditationsProcessed': 'Accreditaties zijn verwerkt',

        'copy': 'Kopie',
        'newBlock': 'Nieuw blok',
        'newBlockItem': 'Nieuw e-learning onderdeel',

        'publish': 'publiceren',
        'unpublish': 'terugdraaien publicatie',
        'preview': 'afspelen',
        'duplicate': 'dupliceren',
        'delete': 'verwijderen',
        'remove': 'verwijderen',
        'create': 'aanmaken',
        'save': 'opslaan',
        'ok': 'ok',
        'cancel': 'annuleren',
        'preview': 'afspelen',
        'select': 'selecteren',
        'distribute': 'uitleveren',
        'add': 'toevoegen',
        'preset': 'instellen',
        'continue': 'doorgaan',
        'replace': 'vervangen',
        'rollback': 'herstellen',
        'sort': 'sorteren',

        'publishHeading': 'Arrangement publiceren?',
        'publishHeadingLtiItem': 'LTI koppeling publiceren?',
        'publishHeadingScormItem': 'SCORM pakket publiceren?',
        'publishBody': 'De huidige versie van het arrangement wordt gepubliceerd.',
        'publishBodyLtiItem': 'Deze LTI koppeling wordt gepubliceerd naar de Catalogus.',
        'publishBodyScormItem': 'Dit SCORM pakket wordt gepubliceerd naar de Catalogus.',
        'unpublishHeadingLtiItem': 'Terugdraaien publicatie LTI koppeling?',
        'unpublishBodyLtiItem': 'Deze LTI koppeling wordt verwijderd uit de Catalogus.',
        'unpublishBodyScormItem': 'Dit SCORM pakket wordt verwijderd uit de Catalogus.',
        'duplicateHeading': 'Arrangement dupliceren?',
        'duplicateHeadingtiItem': 'LTI koppeling dupliceren?',
        'deleteHeading': 'Arrangement verwijderen?',
        'deleteHeadingtiItem': 'LTI koppeling verwijderen?',
        'deleteHeadingScormItem': 'SCORM pakket verwijderen?',
        'createHeading': 'Nieuw arrangement aanmaken?',
        'createHeadingtiItem': 'Nieuwe LTI koppeling aanmaken?',
        'createHeadingScormItem': 'Nieuw SCORM pakket aanmaken?',
        'urlHeading': 'Selecteer een afbeelding',
        'deleteBlockHeading': 'Blok verwijderen?',
        'deleteBlockItemHeading': 'E-learning onderdeel verwijderen?',
        'chooseMicrolearning': 'Kies een microlearning',
        'chooseElearning': 'Kies een e-learning onderdeel',
        'distributeHeading': 'Arrangement uitleveren?',
        'distributeBody': '"${this.compositionName}" zal worden uitgeleverd op de volgende portalen:',
        'replaceHeading': 'Catalogus items vervangen?',
        'replaceBody': '"${this.compositionName}" zal het geselecteerde catalogus item vervangen op de geselecteerde portalen:',
        'presetHeading': 'Standaard instellingen',
        'presetBody': 'Wil je de standaard instellingen voor deze klant gebruiken? De huidige instellingen zullen worden overschreven.',
        'dirtyFormHeading': 'Wijzigingen niet opgeslagen',
        'dirtyFormBody': 'Er zijn wijzigingen aangebracht die niet zijn opgeslagen. Weet je zeker dat je door wil gaan?',
        'processHeading': 'Accreditaties verwerken',
        'processBody': 'Alle resultaten van deze opleiding zullen direct worden verwerkt. Het is mogelijk dat dit enige tijd duurt. Wil je doorgaan met verwerken?',
        'rollbackHeading': 'Herstellen vervangen',
        'rollbackBody': 'Weet je zeker dat je het vervangen wilt herstellen? Alle eigenschappen worden indien mogelijk teruggezet. Ook alle gebruikers zullen weer worden gekoppeld aan het vorige catalogus item.',

        'lastModified': 'laatst gewijzigd',
        'lastPublished': 'laatst gepubliceerd',
        'generalSettings': 'Algemene instellingen',

        'title': 'Werktitel',
        'description': 'Omschrijving',
        'displayTitle': 'Titel',
        'summary': 'Samenvatting',
        'duration': 'Doorlooptijd',
        'requiredDuration': 'Verplichte doorlooptijd',
        'lti': 'LTI koppeling',
        'url': 'URL',
        'filename': 'Bestandsnaam',
        'masteryScore': 'Cesuur',
        'blockType': 'Blok type',
        'blockItem': 'E-learning onderdeel',
        'blockItemType': 'Type e-learning onderdeel',
        'successDeterminator': 'Verplicht voor afronding',
        'minutes': 'minuten',
        'search': 'Zoeken naar',
        'numberResults': 'Aantal resultaten',
        'noMicrolearningsFound': 'Er zijn geen microlearnings gevonden',
        'noElearningsFound': 'Er zijn geen e-learning onderdelen gevonden',
        'noCompositionsFound': 'Er zijn geen arrangementen gevonden',
        'noLtiItemsFound': 'Er zijn geen LTI koppelingen gevonden',
        'noScormItemsFound': 'Er zijn geen SCORM pakketten gevonden',
        'noOsItemsFound':  'Er zijn geen Online Studio microlearnings gevonden',
        'noAccreditationsFound': 'Er zijn geen accreditaties',
        'noAccreditationInfoFound': 'Er zijn geen accreditatie instellingen gevonden',
        'noReplacements': 'Er is geen catalogus item vervangen',
        'invalidScormPackage': 'Geen geldig SCORM 1.2 pakket.',
        'search': 'zoeken',
        'distributePortalsLabel': 'Portalen',
        'distributePortalsPlaceholder': 'Selecteer 1 of meerdere portalen',
        'noPortals': 'Nog niet uitgeleverd.',
        'name': 'Naam',
        'launchUrl': 'Launch Url',
        'consumerKey': 'Consumer Key',
        'sharedSecret': 'Shared Secret',
        'settings': 'Instellingen',
        'finishImmediately': 'Rondt gelijk af bij opstarten',
        'finishAfterNumberOfGrades': 'Rondt af na aantal scores',
        'finishScore': 'Score',
        'masteryScore': 'Cesuur',
        'canScore': 'Meldt score',
        'bigNumberParameter': 'BIG-nummer parameter',
        'fullScreen': 'Volledig scherm',
        'requireNewWindow': 'Opent in een nieuw scherm',
        'anonymous': 'Anonieme gebruikergegevens',
        'finishScore': 'Afronden bij score',
        'masteryScore': 'Cesuur',
        'canScore': 'Toon score',
        'fullScreen': 'Volledig scherm',
        'requireNewWindow': 'Nieuw scherm',
        'anonymous': 'Anonieme gebruikergegevens',
        'parameters': 'Parameters',
        'newKey': 'Nieuwe parameter',
        'keys': 'Sleutelwaardes',
        'completionType': 'Afronding',
        'scoreType': 'Rapportage',
        'keySecretsList': 'Eerder ingevoerde sleutelwaardes',
        'supplier': 'Leverancier',
        'playerType': 'Type',
        'all': 'Alle',
        'replaceElearning': 'Te vervangen catalogus item',
        'replacedElearning': 'Vervangen catalogus item',
        'visibleUntil': 'Zichtbaar tot',
        'latestVersion': 'Huidige versie',
        'vEnVnNumberParameter': 'V&VN-nummer parameter',
        'version': 'Versie',
        'package': 'Pakket',
        'uploaded': 'Geüpload',
        'uploadNewVersion': 'Upload nieuwe versie',
        'uploadNewPackage': 'Upload nieuw pakket',
        'scormPackageNotEnabled': 'SCORM pakket is nog niet gereed',
        'loading': 'bezig om gegevens op te halen...',
        'selectElearningItem': 'Kies een e-learning onderdeel',
        'launchType': 'Opstart type',
        'newWindowMessage': 'Deze e-learning zal worden gestart in een nieuw scherm. Klik op onderstaande knop om de e-learning te starten.',
        'newWindowOpenedMessage': 'De e-learning is automatisch geopend in een nieuw scherm.',
        'startInNewWIndow': 'Klik hier om de e-learning te openen',
        'completionSettings': 'Afrondingsinstellingen',
        'completionType': 'Afronding blokken',
        'progressType': 'Afronding onderdelen',
        'minItems': 'Aantal blokken',

        'REQUIRED_BLOCKS': 'Selecteer welke blokken verplicht zijn',
        'ANY_BLOCK': 'Tenminste 1 blok dient afgerond te zijn',
        'ALL_BLOCKS': 'Alle blokken moeten afgerond zijn',
        'MIN_NUM_BLOCKS': 'Tenminste dit aantal blokken dient afgerond te zijn',
        'ANY_ITEM': 'Tenminste één item moet worden afgerond',
        'ALL_ITEMS': 'Alle items moeten worden afgerond',

        'helper_min2chars': 'Voer minimaal 2 tekens in om te zoeken.',
        'helper_activeWhenPortalsSelected': 'Selecteer tenminste 1 portaal.',
        'helper_portalSearch': 'Selecteer 1 of meerdere portalen',
        'helper_copyOptions': 'Toewijzingen en bekwaamheden worden automatisch overgezet.',
        'warning_replaceToPortals': 'Dit catalogus item wordt nu vervangen op de volgende portalen:',
        'warning_noReplace': 'Geen portalen gevonden waarop vervangen kan worden.',
        'replaceAgain': 'Ook opnieuw vervangen op deze portalen',
        'allPortals': 'Alle portalen',

        'expired': 'verlopen',
        'aboutToExpire': 'verloopt binnenkort',
        'status': 'Status',
        'process': 'Verwerken',
        'processed': 'Verwerkt',
        'result': 'Resultaat',
        'user': 'Gebruiker',

        'accr.status.accepted': 'Geaccepteerd',
        'accr.status.failed': 'Mislukt',
        'accr.status.error': 'Fout',
        'accr.status.invalid': 'Ongeldig',
        'accr.status.badResponse': 'Server fout',
        'accr.endDate': 'Voldaan',

        'preset_default': 'Rondt af bij een resultaat van 100',
        'preset_immediately': 'Rondt direct af',
        'preset_firstResult': 'Rondt af bij het eerste resultaat',
        'preset_score': 'Rondt af wanneer het resultaat hoger of gelijk is aan',
        'preset_noScore': 'Geen score',
        'preset_showScore': 'Score',
        'preset_masteryScore': 'Score en voldaan/niet voldaan op basis van cesuur',
        'supplier': 'Leverancier',
        'playerType': 'Type',
        'all': 'Alle',

        'pe.externalPersonId': 'PE-online Persoons ID',
        'pe.courseId': 'PE-online Cursus ID',
        'pe.editionId': 'PE-online Editie ID',
        'pe.moduleId': 'PE-online Module ID',
        'pe.organization': 'Beroepsorganisatie',
        'pe.duration': 'Looptijd',
        'pe.duration.start': 'Looptijd start datum',
        'pe.duration.end': 'Looptijd eind datum',
        'pe.institution': 'Instelling',
        'pe.points': 'Punten',

        'copyOptions': 'Kopieer de volgende eigenschappen van dit catalogus item:',
        'flags.title': 'Naam',
        'flags.description': 'Omschrijving',
        'flags.summary': 'Samenvatting',
        'flags.image': 'Afbeelding',
        'flags.categories': 'Categorieen',
        'flags.documents': 'Documenten',
        'flags.evaluation': 'Evaluatie formulier',
        'flags.certificate': 'Certficaat',

        'errChooseBetween0_10': 'Kies een aantal tussen 0 en 10',
        'errChooseBetween0_100': 'Kies een score tussen 0 en 100',
        'errRequired': 'Dit veld is verplicht',
        'errorOccurred': 'Er is een fout opgetreden',

        'launchtype_regular': 'In het huidge scherm (standaard)',
        'launchtype_newwindow': 'In een nieuw scherm',
    },
};
