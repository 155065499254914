import { html, css, nothing, LitElement } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import sharedStyles from '../../app/styles.js';
import connect from 'wc-context-redux/connect';
import { distributeComposition } from '../../store/comp/comp.js';
import { searchCatalogItems, clearSearchResults } from '../../store/catalog/catalog.js';
import { loadPortals } from '../../store/portal/portal.js';
import { translate } from '../../helpers/translate.js';
import {
    DEFAULT_COPYOPTIONS,
    copyOptionFlags,
    filterValidPortalIds,
} from '../../store/catalog/catalogHelpers.js';
import '@vaadin/multi-select-combo-box/theme/material/vaadin-multi-select-combo-box.js';
import '../shared/sdb-dialog.js';
import { comboBoxRenderer } from '@vaadin/combo-box/lit.js';
import { Debouncer } from '@polymer/polymer/lib/utils/debounce.js';
import { timeOut } from '@polymer/polymer/lib/utils/async.js';
import '@vaadin/combo-box/theme/material/vaadin-combo-box.js';
import '@vaadin/checkbox/theme/material/vaadin-checkbox.js';
import '@vaadin/checkbox-group/theme/material/vaadin-checkbox-group.js';

const mapStateToProps = (state) => ({
    info: state.comp.current,
    portals: state.portal.list,
    isPortalsLoading: state.portal.status === 'loading' || state.portal.status === 'idle',
    filteredItems: state.catalog.list,
    isCatalogLoading: state.catalog.status === 'loading' || state.catalog.status === 'idle',
});

const mapDispatchToProps = (dispatch) => ({
    distributeComposition: (args) => dispatch(distributeComposition(args)),
    loadPortals: () => dispatch(loadPortals()),
    searchCatalogItems: (search, portals) => dispatch(searchCatalogItems({ search, portals })),
    clearSearchResults: () => dispatch(clearSearchResults()),
});

@customElement('sdb-distribution-dialog')
class SdbDistributionDialog extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: Boolean })
    accessor replace = false;

    @state()
    accessor info = undefined;

    @state()
    accessor portals = [];

    @state()
    accessor isPortalsLoading = false;

    @state()
    accessor filteredItems = [];

    @state()
    accessor isCatalogLoading = false;

    @state()
    accessor valid = false;

    @state()
    accessor canReplace = false;

    @state()
    accessor allPortals = false;

    @state()
    accessor redoPortals = false;

    @query('#distribute_dialog')
    accessor dialog = undefined;

    @query('#portals_mscb')
    accessor portalsSelectBox = undefined;

    @query('#catalog_item_combobox')
    accessor catalogItemComboBox = undefined;

    @query('#copy_options_group')
    accessor copyOptionsGroup = undefined;

    constructor() {
        super();
        this.copyOptions = {};
    }

    open() {
        this.dialog.open();

        this.allPortals = this.alreadyReplaces;
        this.loadPortals();
        this._loadAlreadyReplacedCatalogItem();
        this._validate();
    }

    get selectedPortalIds() {
        const selectedPortals = this.portalsSelectBox?.selectedItems || [];
        return selectedPortals.map((i) => i.portalIdentifier);
    }

    get selectedCatalogItem() {
        if (this.alreadyReplaces) {
            if (!this.allPortals && this.selectedPortalIds.length === 0)
                return null;

            return this.filteredItems.length ? this.filteredItems[0] : [];
        }

        return this.catalogItemComboBox?.selectedItem;
    }

    get alreadyReplaces() {
        return !!this.info.log?.currentReplacedTargetId && this.replace;
    }

    get portalIdsBatch() {
        const portalIds = this.alreadyReplaces
            ? filterValidPortalIds(this.filteredItems?.find(i => true)?.portals, this.portals)
            : filterValidPortalIds(this.selectedCatalogItem?.portals, this.portals);

        const alreadyReplacedOnPortalIds = this.info.portals.filter(p =>
            p.replacesTargetIds?.includes(this.info.log?.currentReplacedTargetId)).map((p) => p.portalIdentifier);

        return this.redoPortals
            ? portalIds
            : portalIds.filter((p) => !alreadyReplacedOnPortalIds.includes(p));
    }

    static get styles() {
        return [
            sharedStyles,
            css`
                vaadin-multi-select-combo-box {
                    width: 500px;
                    --vaadin-combo-box-overlay-width: 700px;
                }

                vaadin-combo-box {
                    width: 500px;
                    --vaadin-combo-box-overlay-width: 700px;
                }

                paper-input {
                    width: 500px;
                }

                vaadin-checkbox {
                    cursor: pointer;
                }
            `,
        ];
    }

    render() {
        const prefix = this.replace ? 'replace' : 'distribute';

        return html`
            <sdb-dialog
                type="form"
                id="distribute_dialog"
                heading=${translate(`${prefix}Heading`)}
                confirmCaption=${translate(prefix)}
                ?confirmDisabled=${!this.valid}
                @confirm=${this._confirm}>
                <div>${translate(`${prefix}Body`, { compositionName: this.info.composition.title })}</div>

                <div>
                    <vaadin-multi-select-combo-box
                        id="portals_mscb"
                        label=${translate('distributePortalsLabel')}
                        helper-text=${translate('helper_portalSearch')}
                        placeholder=${translate('distributePortalsPlaceholder')}
                        .items=${this.portals}
                        item-id-path="portalIdentifier"
                        item-value-path="id"
                        item-label-path="displayName"
                        ?disabled=${this.allPortals}
                        @selected-items-changed=${this._onPortalSelectedItemsChanged}>
                    </vaadin-multi-select-combo-box>

                    <vaadin-checkbox
                        id="all_portals_checkbox"
                        label=${translate('allPortals')}
                        ?hidden=${!this.replace}
                        ?checked=${this.allPortals}
                        @checked-changed=${this._onAllPortalsCheckedChanged}>
                    </vaadin-checkbox>
                </div>

                <div ?hidden=${!this.replace || this.alreadyReplaces}>
                    <vaadin-combo-box
                        id="catalog_item_combobox"
                        label=${translate('replaceElearning')}
                        helper-text=${translate(this.canReplace ? 'helper_min2chars' : 'helper_activeWhenPortalsSelected')}
                        item-id-path="targetId"
                        item-label-path="name"
                        ?loading=${this.isCatalogLoading}
                        ?disabled=${!this.canReplace}
                        .filteredItems=${this.filteredItems}
                        @filter-changed=${this._onFilterChanged}
                        @selected-item-changed=${this._validate}
                        ${comboBoxRenderer(this._catalogItemRenderer, [])}>
                    </vaadin-combo-box>
                </div>

                <div ?hidden=${!this.replace || !this.alreadyReplaces}>
                    <paper-input
                        readonly
                        label=${translate('replaceElearning')}
                        value=${this.info.log?.currentReplacedName}>
                    </paper-input>
                    <vaadin-checkbox
                        id="redo_checkbox"
                        label=${translate('replaceAgain')}
                        @checked-changed=${this._onRedoCheckedChanged}>
                    </vaadin-checkbox>
                </div>

                </div>

                <div ?hidden=${!this.selectedCatalogItem}>
                    ${this._renderPortalChipBar(this.selectedCatalogItem?.portals)}
                </div>

                <div ?hidden=${!this.replace}>
                    <vaadin-checkbox-group
                        id="copy_options_group"
                        label=${translate('copyOptions')}
                        helper-text=${translate('helper_copyOptions')}
                        theme="vertical"
                        ?disabled=${this.alreadyReplaces && !this.redoPortals}
                        .value=${this._getFlagsAsArray(copyOptionFlags, this.info?.log?.currentCopyOptions)}>
                        ${Object.entries(copyOptionFlags).map(([key, value]) => html`
                            <vaadin-checkbox
                                id="flags_${key}"
                                label=${translate(`flags.${key}`)}
                                value=${value}>
                            </vaadin-checkbox>
                        `)}
                    </vaadin-checkbox-group>
                </div>

                ${!this.replace ? html`
                        <div></div>
                    ` : this.portalIdsBatch.length > 0 ? html`
                        <div>
                            <div>${translate('warning_replaceToPortals')}:</div>
                            <div>${this._renderPortalChipBar(this.portalIdsBatch)}<div>
                        </div>
                    ` : html`
                        <div>
                            <div>${translate('warning_noReplace')}:</div>
                        </div>
                    `}

            </sdb-dialog>
        `;
    }

    _loadAlreadyReplacedCatalogItem() {
        if (!this.alreadyReplaces)
            return;

        const portalIds = this.allPortals ? null : this.selectedPortalIds;
        this.searchCatalogItems(this.info.log.currentReplacedTargetId, portalIds, true);
    }

    _onFilterChanged(e) {
        this._onFilterChangedDebouncer = Debouncer.debounce(
            this._onFilterChangedDebouncer, // initially undefined
            timeOut.after(400),
            () => {
                if (!e.detail.value || e.detail.value.length < 2) {
                    this.clearSearchResults();
                    return;
                }

                const portalIds = this.allPortals ? null : this.selectedPortalIds;
                this.searchCatalogItems(e.detail.value, portalIds);
            }
        );
    }

    _onPortalSelectedItemsChanged() {
        this._validate();
        this.catalogItemComboBox.clear();

        if (!this.alreadyReplaces) {
            this.clearSearchResults();
        } else {
            this._loadAlreadyReplacedCatalogItem();
        }
    }

    _onAllPortalsCheckedChanged(e) {
        this.allPortals = e.detail.value;
        this._onPortalSelectedItemsChanged();
    }

    _onRedoCheckedChanged(e) {
        this.redoPortals = e.detail.value;
        this._validate();
    }

    _getFlagsAsArray(options, flags) {
        flags = !isNaN(parseInt(flags, 10)) ? flags : DEFAULT_COPYOPTIONS;
        return Object.values(options)
                    .filter(value =>  (value & flags) === value)
                    .map(String);
    }

    _validate() {
        this.valid = this.selectedPortalIds.length > 0 || this.allPortals === true;
        if (this.replace) {
            this.canReplace = this.selectedPortalIds.length > 0 || this.allPortals === true;
            this.valid &= !!this.selectedCatalogItem && this.portalIdsBatch.length > 0;
        }
    }

    _confirm() {
        this.distributeComposition({
            composition: this.info.composition,
            portalIds: !this.replace
                ? this.selectedPortalIds
                : this.portalIdsBatch,
            replaceTargetId: !this.replace
                ? null
                : this.alreadyReplaces
                    ? this.info.log.currentReplacedTargetId
                    : this.selectedCatalogItem.targetId,
            replaceName: !this.replace
                ? null
                : this.alreadyReplaces
                    ? this.info.log.currentReplacedName
                    : this.selectedCatalogItem.name,
            flags: !this.replace
                ? 0
                : this.alreadyReplaces
                    ? this.info.log.currentCopyOptions || 0
                    : this.copyOptionsGroup.value.reduce((f, o) => f | o, 0),
        });

        this.portalsSelectBox.selectedItems = [];
        this.catalogItemComboBox.selectedItem = null;
    }

    _catalogItemRenderer(item) {
        return html`
            <div class="combo-item">
                <div class="label">${item.name}</div>
                <div class="subtext" ?hidden=${!item.summary} style="overflow: hidden; font-weight: 300;  display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">${item.summary}</div>
                ${this._renderPortalChipBar(item.portals)}
            </div>
        `;
    }

    _renderPortalChipBar(portalIds, maxPortalsVisible = 5) {
        portalIds = filterValidPortalIds(portalIds, this.portals);

        if (!portalIds)
            return nothing;

        const renderPortals = portalIds.slice(0, maxPortalsVisible);
        const remainingPortalCount = portalIds.length - maxPortalsVisible;

        return html`
            <sdb-content-chipbar>
                ${map(renderPortals, (portalId) => html`
                    <sdb-content-chip label=${portalId}></sdb-content-chip>
                `)}
                ${remainingPortalCount > 0
                    ? html`<span title=${portalIds.join(', ')}>+${remainingPortalCount}</span>`
                    : nothing
                }
            </sdb-content-chipbar>
        `;
    }
}
